.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.campaign_header_section {
  display: flex;
  align-items: center;
  gap: 16px;
}

.brief_section {
}

.products_section {
}

.requirements_section {
}
