.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  > * {
    grid-column: 2;
  }

  > :nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
