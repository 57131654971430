.container {
}

.grid_of_campaigns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
}

.skeleton {
  padding-top: 24px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px 16px;

  :nth-child(1) {
    grid-column: 1 / -1;

    :first-child {
      width: 100%;
    }
  }
}

.skeleton_block {
  width: 100% !important;
}
