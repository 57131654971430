.container {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.top_section {
  display: grid;
  grid-template-columns: 1fr auto;
}

.page_content {
  padding: 0px 32px;
}
